<template>
  <div></div>
</template>

<script>
import * as jumpPageApi from "./api"
export default {
  name: 'index',
  data(){
    return{
        //存query参数
        query1:{}
    }
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
    this.getClodccToken()
    this.query1=this.$route.query
  },
  methods:{
    getClodccToken(){
        const parameter = {
          agentId:this.$route.query.agentId,
        }
        jumpPageApi.getClodccToken(parameter).then((res)=>{
            // 跳转自定义页面
            // this.$router.push({
            //   path: "/injectionComponent1",
            //   query: {
            //     page: this.$route.query.pageApi,
            //     binding:res.data.data,
            //     ...this.query1
            //   },
            // });

            // let routeUrl = this.$router.resolve({
            //   path: "/injectionComponent1",
            //   query: {
            //     page: this.$route.query.pageApi,
            //     binding:res.data.data,
            //     ...this.query1
            //   },
            // });
            // // 1.将自己需要跳转的页面定义个变量
            // let mywin=window.open(routeUrl.href, "_self");
            // // 2.将这个变量通过location的方法,并传值true让它获取最新的内容
            // mywin.location.reload(true);
            if(res){
              window.open("/renderer-index.html?binding=" + res.data.data + "&" +window.location.href.split("?")[1], "_self");
              // window.location.href = "/renderer-index.html?binding=" + res.data.data + "&" +window.location.href.split("?")[1]
            }
        })
    }

  }
}
</script>

<style lang = "scss" scoped>

</style>